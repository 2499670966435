<template>
    <div class="site-delivery">
        <CategoriesMenu 
            v-if="categories"
            class="site-delivery__categories"
            :categories="categories"
            :active-category="activeCategory"/>

        <div class="site-delivery__container">
            <SiteDescription
                :site="site"
            />
            
            <Discounts
                v-if="discounts"
                :discounts="discounts"
            />

            <CategoriesMenu 
                v-if="categories"
                :categories="categories"
                :active-category="activeCategory"
                mobile/>

            <div class="site-delivery__categories-list">
                <template v-for="category in categories">
                    <div class="site-delivery__categories-list__item" :key="`category-${category.id}`">
                        <div class="site-delivery__categories-list__item__ancor" :id="`category-${category.id}`"></div>
                        <div :id="category.id" v-observe-visibility="{
                            callback: visibilityChanged,
                            intersection: {
                                rootMargin: '-29% 0px -69% 0px',
                                threshold: 0
                            }
                        }">
                            <div class="site-delivery__categories-list__title">{{ category.title }}</div>
                            <div class="site-delivery__products-list">
                                <template v-for="product in category.products">
                                    <ProductCard
                                        :key="`product-${product.id}`"
                                        :product="product"
                                        @click="details(product)"
                                        @updateSite="updateSite"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <PopupProductDetail
                v-model="showDetails"
                :product="activeProduct"
                @close="closeDetails"
                @updateSite="updateSite"
            />
        </div>
        
        <Cart
            v-if="$vuetify.breakpoint.width > 1400"
            class="site-delivery__cart" />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import SiteDescription from '../../components/site/SiteDescription';
    import Discounts from '../../components/site/Discounts';
    import ProductCard from '../../components/site/ProductCard';
    import PopupProductDetail from '../../components/site/PopupProductDetail';
    import CategoriesMenu from '../../components/site/CategoriesMenu';
    import Cart from '../../components/order/Cart';

    export default {
        name: 'Site',
        metaInfo() {
            return {
                title: `${ this.site.title } - заказать доставку`,
                meta: [
                    { vmid: 'description', name: 'description', content: this.site.description_seo }
                ],
            }
        },
        components: {
            SiteDescription,
            Discounts,
            ProductCard,
            PopupProductDetail,
            CategoriesMenu,
            Cart,
        },
        data: () => ({
            showDetails: false,
            activeProduct: {},
            activeCategory: 32,
            activeCategoryClicked: false
        }),
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            }),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('siteDiscounts', {
                discounts: state => state.entities
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
        },
        async mounted() {
            const { code } = this.$route.params;
            if(code) {
                await store.dispatch('sites/getByCode', { code });
            } else {
                await store.dispatch('sites/getByHost', { host: window.location.host });
            }

            if(!this.site) {
                window.location.href = process.env.VUE_APP_FRONT_URL
            }

            await store.dispatch('categories/fetch', { site_id: this.site.id, filter: { for_delivery: true } });
            await store.dispatch('siteDiscounts/fetch', { filter: { site_id: this.site.id, for_delivery: true } });
            this.activeCategory = this.categories ? +this.categories[0].id : 0;
            
            // Default cart settings
            await store.dispatch('orders/fetchCart', { code: this.site.code });
            await store.dispatch('orders/setCartSite', this.site);
            // Default cart settings

            store.commit('orders/SET_CART_ORDER_CREATED', false);
        },
        methods: {
            updateSite() {
            },
            details(product) {
                this.activeProduct = product;
                this.showDetails = true;
            },
            closeDetails() {
                this.showDetails = false;
            },
            visibilityChanged (isVisible, entry) {
                if(isVisible && !this.activeCategoryClicked) {
                    this.activeCategory = +entry.target.id;
                }
            }
        }
    }
</script>

<style lang="scss">
.site-delivery {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    scroll-behavior: smooth;

    &__container {
        width: calc(100% - 620px);
        min-width: calc(100% - 620px);
        max-width: 1200px;

        @media screen and (max-width: 1400px) {
            width: calc(100% - 250px);
            min-width: calc(100% - 250px);
        }
    
        @media screen and (max-width: 900px) {
            width: 100%;
            min-width: 100%;
        }
    }

    &__categories-list {
        padding-top: 20px;

        &__item {
            position: relative;

            &__ancor {
                position: absolute;
                top: -70px;

                @media screen and (max-width: 900px) {
                    top: -140px;
                }
            }
        }

        &__title {
            padding: 20px 0 0 16px;
            font-size: 17px;
            line-height: 24px;
            font-weight: 700;
        }
    }

    &__products-list {
        padding: 20px 0;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(5, 1fr);
        
        @media screen and (max-width: 1640px) {
            grid-template-columns: repeat(4, 1fr);
        }
        
        @media screen and (max-width: 1440px) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        @media screen and (max-width: 1400px) {
            grid-template-columns: repeat(4, 1fr);
        }
        
        @media screen and (max-width: 1100px) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        @media screen and (max-width: 660px) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        
        @media screen and (max-width: 360px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 0;
        }
    }

    &__cart {
        width: 350px;
        min-width: 350px;
        height: calc(100vh - 90px);
        position: sticky;
        top: 90px;

        @media screen and (max-width: 1400px) {
            display: none;
        }
    }
}
</style>
